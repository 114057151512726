export default [
  "accomplishment",
  "achievement",
  "activity",
  "completion",
  "creation",
  "deeds",
  "effort",
  "elbow grease",
  "endeavor",
  "enterprise",
  "escapade",
  "exploit",
  "feat",
  "grind",
  "gumption",
  "handiwork",
  "happenings",
  "job",
  "jorb",
  "mission",
  "muscle",
  "operation",
  "output",
  "performance",
  "profession",
  "production",
  "project",
  "pursuit",
  "rendezvous",
  "stint",
  "strive",
  "toil",
  "trade",
  "travail",
  "trial",
  "triumph",
  "undertaking",
  "venture",
  "work",
];
