export default [
  "bear fruit",
  "carry the day",
  "catch fire",
  "conquer",
  "contend",
  "deliver",
  "deliver the goods",
  "excel",
  "flourish",
  "get ahead",
  "hit it big",
  "kick butt",
  "make the grade",
  "overcome",
  "proliferate",
  "prevail",
  "prosper",
  "succeed",
  "sweep",
  "thrive",
  "triumph",
  "win",
];
