export default [
  "agents",
  "assemblage",
  "alliance",
  "baby",
  "band",
  "brigade",
  "clan",
  "class",
  "club",
  "codeketeers",
  "collective",
  "consortium",
  "company",
  "comrades",
  "contingent",
  "crew",
  "crowd",
  "duo",
  "everyone",
  "faction",
  "folks",
  "frienderoonis",
  "gang",
  "heroes",
  "lineup",
  "my dears",
  "nerds",
  "outfit",
  "partners",
  "party",
  "peasants",
  "posse",
  "side",
  "squad",
  "team",
  "troop",
  "troupe",
  "unit",
  "workers",
  "y’all",
  "you nasty lads",
];
